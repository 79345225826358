<template>
    <div class="header-main">
        <div @click="backHome()" class="header-box">
            <div class="back-icon">
                <i class="el-icon-arrow-left" style="font-weight: bold" ></i>
                <span>返回</span>
            </div>
            <div class="header-title">{{title}}</div>
            <div @click="backHome()" class="home-icon">
                <i class="el-icon-s-home" style="font-weight: bold" ></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Header',
        components: {
        },
        props: {
            title: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
            }},
        created() {
        },
        mounted:function(){
        },
        methods: {
            // 返回首页
            backHome(){
                this.$router.push({ path:'/home', query:{} })
            }
        }
    }
</script>

<style scoped>
    .header-box{
        width: 100vw;
        height: 3rem;
        background-color: #2586FF;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .back-icon{
        padding-left: 2rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
    }
    .header-title{
        font-size: 1rem;
        color: #ffffff;
    }
    .home-icon{
        padding-right: 2rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
    }
</style>
