<template>
    <div class="main">
        <header-common title="我的作品"></header-common>
        <div class="list-box">
            <div @click="toInfo(item)" v-for="(item,index) in list" :key="index" class="item-box">
                <el-card :body-style="{ padding: '10px' }">
                    <img :src="item.img" class="image">
                    <div style="padding: 14px;">
                        <span>{{item.title}}</span>
                        <div class="bottom clearfix">
                            <time class="time">{{item.add_time}}</time>
                            <el-button type="text" class="button">查看详情</el-button>
                        </div>
                    </div>
                </el-card>
            </div>
            <div style="width: 100vw;height: 100px"></div>
        </div>
        <div class="page-box">
            <el-pagination v-show="total>0" background layout="prev, pager, next" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.pageSize" @current-change="handleCurrentChange" />
        </div>
        <!--没有数据提示-->
        <div class="empty-box" v-if="list.length <= 0">
            <img class="empty-img" src="https://img.nanshikeji.cn/img/common/empty.png">
            <div class="empty-text">还没有作品 学习完成之后 可以来这里查看作品</div>
        </div>
        <!--查看弹窗-->
        <div v-if='dialogCode' class="container">
            <div class="dialog-code-box">
                <div class="dialog-chat-title">
                    <div class="dialog-chat-title-left">
                        查看作品<span class="tip-green" />
                    </div>
                    <div class="hand" @click="dialogCode=false">
                        <i class="el-icon-close" style="font-size: 1.5rem" />
                    </div>
                </div>
                <div class="iframe-box">
                    <iframe :key="key" ref="iframe" :src="studyUrl" style="width: 50vw;height: 75vh;overflow-x:hidden;margin-top: 5px" frameborder="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getWorks
    } from '@/api/app'
    import HeaderCommon from '@/components/Header'
    export default {
        name: 'works',
        // 添加组件
        components: {
            HeaderCommon
        },
        data() {
            return {
                numAll:'',
                list: null,
                total: 0,
                listLoading: false,
                listQuery: {
                    status:1,
                    page: 1, // 页数
                    pageSize: 10 // 每页条数
                },
                postData: {},
                dialogCode: false,
                studyUrl:'',
                key:0
            }},
        created() {
            this.getList()
        },
        mounted:function(){
        },
        methods: {
            // 获取列表
            getList(){
                this.listLoading = true
                getWorks(this.listQuery).then(res => {
                    this.listLoading = false
                    this.list = res.data.list
                    this.total = res.data.count
                })
            },
            // 切换分页
            handleCurrentChange(val) {
                this.listQuery.page = val
                this.getList()
            },
            // 跳转到详情页
            toInfo(item){
                this.studyUrl = '/show/player.html?projectUrl='+item.url
                this.dialogCode = true
            }
        }
    }
</script>

<style scoped>
    .main{
        width: 100vw;
        height: 100vh;
        position: relative;
       /* background-color: #f6c10a;*/
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .list-box{
        width: 90vw;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap:wrap;
    }
    .item-box{
        padding: 1.5rem 1.5rem 0 0;
    }
    .time {
        font-size: 13px;
        color: #999;
    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
    }

    .button {
        padding: 0;
        float: right;
    }

    .image {
        width: 220px;
        display: block;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }
    .page-box{
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 0;
        margin: 2vw 0;
    }
    .container {
        position: fixed;
        top: 0; right: 0; bottom: 0; left: 0;
        /* for IE8 */
        background: url(data:image/png;base64,iVB...g==);
        /* for IE9+ */
        background: rgba(0,0,0,.5);
        text-align: center;
        white-space: nowrap;
        z-index: 999;
    }
    .iframe-box{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .dialog-code-box {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        white-space: normal;
        width: 70vw;
        height: 90vh;
        background: #FFFFFF;
        box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
        border-radius: 2px;
        font-size: 14px;
    }
    /deep/ .el-dialog__body{
        padding: 0 !important;
    }
    .container:after {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    .dialog-chat-box {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        white-space: normal;
        width: 668px;
        height: 588px;
        background: #FFFFFF;
        box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
        border-radius: 2px;
        font-size: 14px;
    }
    .dialog-chat-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #F1F2F6;
        padding: 15px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
    }
    .tip-green{
        width: 10px;
        height: 10px;
        background-color: #07C160;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px 0 5px;
    }
    .empty-box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .empty-img{
        width: 300px;
        height: 300px;
    }
    .empty-text{
        font-size: 30px;
        color: #a0aaad;
        font-weight: bold;
    }
</style>
